// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../images/arma3sync.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "../images/play_with_six.png";
import ___CSS_LOADER_URL_IMPORT_2___ from "../images/teamspeak.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arma3sync-icon {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  display: block;\n  height: 54px;\n  width: 54px;\n}\n\n.playwithsix-icon {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: contain;\n  display: block;\n  height: 54px;\n  width: 63px;\n}\n\n.teamspeak-icon {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-size: contain;\n  display: block;\n  height: 54px;\n  width: 54px;\n}\n", "",{"version":3,"sources":["webpack://./src/operations/css/icons.css"],"names":[],"mappings":"AAAA;EACE,yDAAgD;EAChD,wBAAwB;EACxB,cAAc;EACd,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,yDAAoD;EACpD,wBAAwB;EACxB,cAAc;EACd,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,yDAAgD;EAChD,wBAAwB;EACxB,cAAc;EACd,YAAY;EACZ,WAAW;AACb","sourcesContent":[".arma3sync-icon {\n  background-image: url('../images/arma3sync.png');\n  background-size: contain;\n  display: block;\n  height: 54px;\n  width: 54px;\n}\n\n.playwithsix-icon {\n  background-image: url('../images/play_with_six.png');\n  background-size: contain;\n  display: block;\n  height: 54px;\n  width: 63px;\n}\n\n.teamspeak-icon {\n  background-image: url('../images/teamspeak.png');\n  background-size: contain;\n  display: block;\n  height: 54px;\n  width: 54px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
